<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        国家级放射影像专业质控中心工作会议
      </div>
      <section>
        <div class="div_p">
          2024年8月9日，国家级放射影像专业质控中心工作会议近日在北京国家会议中心召开，来自全国各地的放射影像质控专家、质控中心负责人及秘书齐聚一堂，共同探讨和交流放射影像质量控制工作，旨在提升我国放射影像诊断质量和患者安全。
        </div>
        <div>
          <img src="@/assets/HY20240815/1.png" alt="">
        </div>

        <div class="div_p">
          会议中，国家级放射影像专业质控中心主任委员金征宇教授发表致辞，金教授首先对各位专家的到来表示诚挚的欢迎，并希望与会专家能够充分利用这个平台，积极交流、分享经验，共同探讨放射影像领域的发展趋势和未来方向。
        </div>
        <div>
          <img src="@/assets/HY20240815/2.png" alt="">
        </div>

        <div class="div_p">
          国家卫生健康委医政司医疗质量与评价处高嗣法副处长线上致辞时表示，国家级放射影像专业质控中心工作取得了显著进展，完成了专业基线情况的摸底和质控指标的制定。他强调，质控指标是工具，不是要求，应以指标为导向，持续改进医疗质量。他还指出，质控工作要与全面提升医疗质量行动相结合，以省、市级质控中心为标杆，带动基层医院提升质控水平。
        </div>
        <div>
          <img src="@/assets/HY20240815/3.png" alt="">
        </div>

        <div class="div_p">
          国家级放射影像专业质控中心于静秘书在会议中宣读了国家级放射影像专业质控中心工作的章程，包括亚专业指标专家组工作章程和哨点医院工作制度。
        </div>
        <div>
          <img src="@/assets/HY20240815/4.png" alt="">
        </div>

        <div class="div_p">
          此外，国家级放射影像专业质控中心副主任委员薛华丹教授对质控中心2023年工作情况和2024年工作计划进行了详细解读，同时介绍了亚专业组的成立和工作进展，以及质控指标体系建设、国家质控哨点医院网络建设等工作，并对未来工作提出了具体建议。
        </div>
        <div>
          <img src="@/assets/HY20240815/5.png" alt="">
        </div>

        <div class="div_p">
          会议还分别邀请了X线亚专业组组长张惠茅教授和腹部影像亚专业组组长严福华教授汇报各自亚专业组的工作进展和经验。张惠茅教授分享了X线亚专业组在BI-RADS规范、行业规范调研和质控指标培训等方面的成果。严福华教授则介绍了腹部影像亚专业组在肝脏影像检查调研、危急值培训和单病种质控指标制定等方面的经验。
        </div>
        <div>
          <img src="@/assets/HY20240815/6.png" alt="">
        </div>
        <div class="div_tit">
          张惠茅教授进行亚专业组工作汇报与经验分享
        </div>
        <div>
          <img src="@/assets/HY20240815/7.png" alt="">
        </div>
        <div class="div_tit">
          严福华教授对亚专业组工作汇报与经验分享
        </div>

        <div class="div_p">
          孙昊教授详细解读了放射影像国家质控指标的内涵和建立流程，强调了质控指标的意义在于引导和促进医疗质量的持续改进，而非考核工具。他还介绍了2024版全国放射影像质控指标体系，并分享了危急值摸底调研、单检查报告质量调研等工作的经验。
        </div>
        <div>
          <img src="@/assets/HY20240815/8.png" alt="">
        </div>
        <div class="div_tit">
          孙昊教授发表质控指标内涵与建立流程解读
        </div>
        <div class="div_p">
          在金征宇教授的主持下，各省质控中心主任委员纷纷发言，分享了各自在质控工作中的经验和体会。他们表示，国家放射影像专业质控中心的工作为全国质控工作提供了方向和指导，各省将认真贯彻落实会议精神，进一步完善质控体系，提升质控水平。
        </div>
        <div>
          <img src="@/assets/HY20240815/9.png" alt="">
        </div>
        <div class="div_p">
          会议最后，国家级放射影像专业质控中心主任委员金征宇教授在总结发言中再次强调了质控工作的定义和指标的重要性，并要求各省质控中心将质控工作作为一项重要职责，持续改进医疗质量，推动影像互认，为患者提供更安全、更优质的医疗服务。强调，放射影像质量控制是一项长期、复杂的工作，需要各级医疗机构、亚专业组和质控专家共同努力，不断探索和创新，才能实现质控工作的目标。
        </div>

      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "HY20240815",
  data() {
    return {
    }
  },

}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  width: 100%;
  box-sizing: border-box;
  margin: 10px auto;
  display: block;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.h2 {
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>